import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  SEOPagesNumrooms,
  SEOPageLinkPType,
  SEOPageTabLink,
  SEOPageFormatMenus,
  SEOPageFormatSelects,
  SEOTabsForPTypesAndNumRooms,
  chooseSeoFooterToUse,
  hasToShowNumRooms,
  SEOTitleEvery,
} from '../../helpers/SEOPagesHelpers';

import {
  isChildOf,
} from '../../helpers/DOMHelpers';

import * as helpers from './helpers/seoFooter';

import './SeoFooter.css';
import SeoFooterBigCities from './SeoFooterBigCities';

const SeoFooter = (props) => {
  const {
    device,
    category,
    orientation,
    buildingCondition,
    seoFooter,
    categoryMenuValues,
    pTypesMenuValues,
    onlyAuction,
    numRoomMenuValues,
    licenseTypeGroupMenuValues,
    isAgencyHome,
    channel,
    seoFooterBigCities,
  } = props;

  const tab = helpers.chooseTab({ isAgencyHome, channel, onlyAuction });
  const [activeNumRoomsTab, setActiveNumRoomsTab] = useState('');
  const [activePtypeValue, setActivePtypeValue] = useState('');
  const [numRooms, setNumRooms] = useState('');
  const [ptype, setPtype] = useState('');
  const [contextTab, setContextTab] = useState('default');
  const [keyTab, setKeyTab] = useState('all');
  const [seoFooterToUse, setSeoFooterToUse] = useState(chooseSeoFooterToUse({ context: contextTab, key: keyTab, seoFooterData: seoFooter }));
  const [shouldShowNumRoomsMenu, setShouldShowNumRoomsMenu] = useState(hasToShowNumRooms({ category, ptype }));
  
  const [licenseTypeGroup, setLicenseTypeGroup] = useState('');
  const [shouldShowLicenseTypeGroupsMenu, setShouldShowLicenseTypeGroupsMenu] = useState(false);

  const { affitto = [], vendita = [] } = seoFooterBigCities;
  const hasSeFooterBigCities = affitto.length > 0 || vendita.length > 0;
  // const ptypeForLinks = !isAgencyHome ? SEOPageLinkPType(propertyType, numRooms) : [];
  const tabLink = helpers.chooseTabLink({
    isAgencyHome,
    category,
    ptype,
    numRooms,
    buildingCondition,
    onlyAuction,
  });

  const [listDataSeoFooter, setListDataSeoFooter] = useState(helpers.formatSeoList(seoFooterToUse, SEOTitleEvery(category, buildingCondition, isAgencyHome, onlyAuction), device));
  let selectOpen = null;

  const switchTab = ({ value = 'all', context = 'default' }) => {
    const val = value === 'all' ? '' : value;
    const seoFooterToUseTmp = chooseSeoFooterToUse({ context, key: value, seoFooterData: seoFooter });
    setContextTab(context);
    setKeyTab(value);
    setSeoFooterToUse(seoFooterToUseTmp);
    let ptypeForLinks = [];
    switch (context) {
      case 'ptypes':
        setActiveNumRoomsTab('');     
        setLicenseTypeGroup(''); 
        setActivePtypeValue(val);
        if (category === 'commerciale') {
          const ltgSet = licenseTypeGroupMenuValues.filter(x => Object.keys(x)[0] === val);
          setShouldShowLicenseTypeGroupsMenu(ltgSet.length > 0 ? ltgSet[0][val] : false); 
        }
        ptypeForLinks = !isAgencyHome ? SEOPageLinkPType({ ptype: val, numRooms }) : [];
        setShouldShowNumRoomsMenu(hasToShowNumRooms({ category, ptype: val }));
        setListDataSeoFooter(!isAgencyHome ? helpers.formatSeoList(seoFooterToUseTmp, ptypeForLinks, device) : []);
        setPtype(val);
        break;

      case 'nRooms':
        setActiveNumRoomsTab(val);
        ptypeForLinks = !isAgencyHome ? SEOPageLinkPType({ ptype, numRooms: val }) : [];
        setListDataSeoFooter(!isAgencyHome ? helpers.formatSeoList(seoFooterToUseTmp, ptypeForLinks, device) : []);
        setNumRooms(val);
        break;

      case 'licenseTypeGroups':
        setLicenseTypeGroup(val);
        if (!val) {
          setShouldShowLicenseTypeGroupsMenu(false);
          ptypeForLinks = !isAgencyHome ? SEOPageLinkPType({
            ptype,
            numRooms: '',
          }) : [];
        } else {
          ptypeForLinks = !isAgencyHome ? SEOPageLinkPType({
            ptype: '',
            ltGroup: shouldShowLicenseTypeGroupsMenu.filter(ltg => ltg.value === val)[0].name,
            numRooms: '',
          }) : [];
        }
        setListDataSeoFooter(!isAgencyHome ? helpers.formatSeoList(seoFooterToUseTmp, ptypeForLinks, device) : []);
        break;        

      default:
        setLicenseTypeGroup('');
        setActiveNumRoomsTab('');
        setActivePtypeValue('');
        setShouldShowLicenseTypeGroupsMenu(false);
        setShouldShowNumRoomsMenu(true);
        ptypeForLinks = !isAgencyHome ? SEOPageLinkPType({ ptype, numRooms: val }) : [];
        setListDataSeoFooter(!isAgencyHome ? helpers.formatSeoList(seoFooterToUseTmp, ptypeForLinks, device) : []);
        break;
    }
  };  

  const onSelectChange = ({ e, data, values }) => {
    e.preventDefault();
    const select = e.target.parentNode.parentNode.parentNode;
    select.classList.remove('selectOpen');
    const newButtonValue = e.target.innerHTML === 'Tutti' ? `Scegli: ${values.map(innerType => innerType.name).join(', ')}` : e.target.innerHTML;
    select.firstChild.innerHTML = newButtonValue;
    if (
      e.target.innerHTML === 'Tutti'
      && !select.classList.contains('noselection')
    ) {
      select.classList.add('noselection');
    } else if (
      e.target.innerHTML !== 'Tutti'
      && select.classList.contains('noselection')
    ) {
      select.classList.remove('noselection');
    }

    const { value, group } = data;
    switch (group) {
      case 'nRooms':
      case 'ptypes':
        switchTab({ value, context: group });
        break;
      case 'default':
        switchTab({ value: 'all', context: group });
        break;
      default:
        document.location = data.href || e.target.href;
        break;
    }
  };

  const outerClick = (e) => {

    if (!isChildOf(e.target, selectOpen)) {
      selectOpen.classList.remove('selectOpen');
      window.removeEventListener('click', outerClick);
      selectOpen = null;
    }
  };

  const onButtonClick = (e) => {
    if (e.target.parentNode.classList.contains('selectOpen')) {
      e.target.parentNode.classList.remove('selectOpen');
      window.removeEventListener('click', outerClick);
      selectOpen = null;
    } else {
      e.target.parentNode.classList.add('selectOpen');
      window.addEventListener('click', outerClick);
      selectOpen = e.target.parentNode;
    }
  };

  const appNRooms = numRooms ? SEOPagesNumrooms[numRooms].singular.toLowerCase() : '';
  const useSelects = device === 'smartphone' || (device === 'tablet' && orientation === 'portrait');

  const ptypesmenu = SEOPageFormatMenus(pTypesMenuValues, ptype, 'ptypes', null, onButtonClick, onSelectChange);

  const createChannelLabelTab = ({ label = '', type = '' }) => {
    const anchor = tabLink?.indexOf('#seoFooter') > -1 ? '' : '/#seoFooter';
    if (type === 'simple') {
      return (
        <li>
          <strong>{label}</strong>
        </li>
      );
    }
    if (type === 'rent') {
      return (
        <li>
          <a
            href={`/liste/affitto${tabLink}${anchor}`}
            title="Guarda i risultati in affitto"
          >
            In Affitto
          </a>
        </li>
      );
    }
    if (type === 'buy') {
      let href = '/#seoFooter';
      if (tabLink !== '/#seoFooter' && tabLink !== '') {
        href = `/liste/vendita${tabLink}${anchor}`;
      }

      return (
        <li>
          <a
            href={href}
            title="Guarda i risultati in vendita"
          >
            In Vendita
          </a>
        </li>
      );
    }
    if (type === 'agency') {
      return (
        <li>
          <a
            href="/agenzie/#seoFooter"
            title="Guarda i risultati delle agenzie"
          >
            Agenzie
          </a>
        </li>
      );
    }
    if (type === 'auction') {
      return (
        <li>
          <a
            href="/liste/aste/#seoFooter"
            title="Guarda i risultati per le aste"
          >
            Aste
          </a>
        </li>
      );
    }
  };


  const createChannelTabs = () => {

    switch (tab) {
      case 'agency':
        return (
          <ul className="tabs ag">
            {createChannelLabelTab({ type: 'buy', label: 'In Vendita' })}
            {createChannelLabelTab({ type: 'rent', label: 'In Affitto' })}
            {createChannelLabelTab({ type: 'simple', label: 'Agenzie' })}
            {createChannelLabelTab({ type: 'auction', label: 'Aste' })}
          </ul>
        );

      case 'auction':
        return (
          <ul className="tabs ag">
            {createChannelLabelTab({ type: 'buy', label: 'In Vendita' })}
            {createChannelLabelTab({ type: 'rent', label: 'In Affitto' })}
            {createChannelLabelTab({ type: 'agency', label: 'Agenzie' })}
            {createChannelLabelTab({ type: 'simple', label: 'Aste' })}
          </ul>
        );

      case 'rent':
        return (
          <ul className="tabs">
            {createChannelLabelTab({ type: 'buy', label: 'In Vendita' })}
            {createChannelLabelTab({ type: 'simple', label: 'In Affitto' })}
            {createChannelLabelTab({ type: 'agency', label: 'Agenzia' })}
            {createChannelLabelTab({ type: 'auction', label: 'Aste' })}

          </ul>
        );
      case 'buy':
      default:
        return (
          <ul className="tabs">
            {createChannelLabelTab({ type: 'simple', label: 'In Vendita' })}
            {createChannelLabelTab({ type: 'rent', label: 'In Affitto' })}
            {createChannelLabelTab({ type: 'agency', label: 'Agenzia' })}
            {createChannelLabelTab({ type: 'auction', label: 'Aste' })}
          </ul>
        );
    }
  };


  const createCategoryTabsAndSelects = () => {
    if (isAgencyHome) {
      return (<h1 className="agHPTitle tp-w--l">Agenzie immobiliari su Casa.it</h1>);
    }
    if (onlyAuction) {
      return (<h3 className="agHPTitle tp-w--l">Aste su Casa.it</h3>);
    }
    switch (useSelects) {
      case true:
        return SEOPageFormatSelects(categoryMenuValues, category, onButtonClick, onSelectChange, '', false);
      default:
        return (
          <ul className="categoriesMenu">
            {SEOPageFormatMenus(categoryMenuValues, category, '', buildingCondition, onButtonClick, onSelectChange)}
          </ul>
        );
    }
  };

  return (
    <section
      id="seoFooter"
      className="homeslice"
    >
      <SeoFooterBigCities
        data={seoFooterBigCities}
        hasSeFooterBigCities={hasSeFooterBigCities}
      />
      <div className="seoList sw">
        {
          hasSeFooterBigCities && (
            <p className="tp-w--l tp-s--x2l mb--m">
              Immobili in {channel} per provincia
            </p>)
        }
        <menu>
          {
            createChannelTabs()
          }
          {
            createCategoryTabsAndSelects()
          }
          {
            useSelects
            &&
            SEOPageFormatSelects(pTypesMenuValues, ptype, onButtonClick, onSelectChange, 'ptypes', onlyAuction)
          }
          {
            !useSelects && ptypesmenu && !onlyAuction
            &&
            <ul className="propertyTypesMenu">
              {
                SEOTabsForPTypesAndNumRooms({
                  groupMenuValue: pTypesMenuValues,
                  appNRooms,
                  pageType: 'ptypes',
                  currentValue: activePtypeValue,
                  switchTab,
                  onlyAuction,
                })
              }
            </ul>
          }
          {
            useSelects && shouldShowNumRoomsMenu && !onlyAuction
            &&
            SEOPageFormatSelects(
              numRoomMenuValues,
              appNRooms,
              onButtonClick,
              onSelectChange,
              'numRooms',
              onlyAuction,
            )
          }
          {
            !useSelects && shouldShowNumRoomsMenu && ptypesmenu && !onlyAuction
            &&
            <ul className="numRoomsMenu">
              {
                SEOTabsForPTypesAndNumRooms({
                  groupMenuValue: numRoomMenuValues,
                  appNRooms,
                  pageType: 'numRooms',
                  currentValue: activeNumRoomsTab,
                  switchTab,
                  onlyAuction,
                })}
            </ul>
          }
          {
            useSelects && shouldShowLicenseTypeGroupsMenu && !onlyAuction
            &&
            SEOPageFormatSelects(
              shouldShowLicenseTypeGroupsMenu,
              licenseTypeGroup,
              onButtonClick,
              onSelectChange,
              'licenseTypeGroups',
              onlyAuction,
            )
          }
          {
            !useSelects && shouldShowLicenseTypeGroupsMenu && ptypesmenu && !onlyAuction
            &&
            <ul className="licenseTypeGroupsMenu">
              {
                SEOTabsForPTypesAndNumRooms({
                  groupMenuValue: shouldShowLicenseTypeGroupsMenu,
                  appNRooms,
                  pageType: 'licenseTypeGroups',
                  currentValue: licenseTypeGroup,
                  switchTab,
                  onlyAuction,
                })}
            </ul>
          }
        </menu>
        <div className="regionsContainer">
          <div className="regionsList">
            {listDataSeoFooter.list1}
          </div>
          <div className="regionsList">
            {listDataSeoFooter.list2}
          </div>
          <div className="regionsList">
            {listDataSeoFooter.list3}
          </div>
          {
            device !== 'tablet'
            &&
            <div className="regionsList">
              {listDataSeoFooter.list4}
            </div>
          }
        </div>
      </div>
    </section>
  );
};

SeoFooter.propTypes = {
  device: PropTypes.string,
  category: PropTypes.string,
  orientation: PropTypes.string,
  channel: PropTypes.string,
  buildingCondition: PropTypes.string,
  onlyAuction: PropTypes.bool,
  seoFooter: PropTypes.instanceOf(Object),
  categoryMenuValues: PropTypes.instanceOf(Array).isRequired,
  pTypesMenuValues: PropTypes.instanceOf(Array).isRequired,
  numRoomMenuValues: PropTypes.instanceOf(Array).isRequired,
  licenseTypeGroupMenuValues: PropTypes.instanceOf(Array).isRequired,
  isAgencyHome: PropTypes.bool,
  seoFooterBigCities: PropTypes.instanceOf(Object),
};

SeoFooter.defaultProps = {
  device: '',
  channel: 'buy',
  category: '',
  orientation: '',
  onlyAuction: false,
  buildingCondition: '',
  isAgencyHome: false,
  seoFooter: null,
  seoFooterBigCities: null,
};

export default SeoFooter;
