import { SEOPageLinkPType, SEOPageTabLink } from '@helpers/SEOPagesHelpers';
import React from 'react';

const formatTowns = (list, ptypeForLinks, withEm = true) => {
  const towns = list.map(
    (town) => {
      const title = town.name.replace(/\-/g, ' ');
      const linkTitle = `Guarda ${ptypeForLinks} a ${title}`;
      const { count } = town;
      const { href } = town;
      return (
        <li key={title}>
          <a
            href={href}
            title={linkTitle}
            data-count={count}
          >
            <span>{title}</span> {withEm && (<em>(comune)</em>)}
          </a>
        </li>
      );
    },
  );
  return (
    <ul>
      {towns}
    </ul>
  );
};

const formatProvinces = (list, ptypeForLinks) => {
  const provinces = list.map(
    (province) => {
      const title = province.name.replace(/\-/g, ' ');
      const linkTitle = `Guarda ${ptypeForLinks} nella provincia di ${title}`;
      const { count, href } = province;
      const towns = 'towns' in province ? formatTowns(province.towns, ptypeForLinks, true) : null;
      const link = towns
        ? (
          <a
            href={href}
            title={linkTitle}
            data-count={count}
          >
            <span>{title}</span> <em>(provincia)</em>
          </a>
        ) : (
          <a
            href={href}
            title={linkTitle}
            data-count={count}
          >
            <span>{title}</span>
          </a>
        );
      return (
        <dd key={title}>
          {link}
          {towns}
        </dd>
      );
    },
  );
  return provinces;
};

export const formatSeoList = (list, ptypeForLinks, device) => {
  const formatted = Object.keys(list).map(
    (region) => {
      const title = region;
      return (
        <dl key={title}>
          <dt>
            {title}
          </dt>
          {formatProvinces(list[title].provinces, ptypeForLinks)}
        </dl>
      );
    },
  );
  const list1 = [];
  const list2 = [];
  const list3 = [];
  const list4 = [];
  if (device !== 'tablet') {
    formatted.forEach(
      (el, index) => {
        if (index < 6) {
          list1.push(el);
        } else if (index < 11) {
          list2.push(el);
        } else if (index < 15) {
          list3.push(el);
        } else {
          list4.push(el);
        }
      },
    );
  } else {
    formatted.forEach(
      (el, index) => {
        if (index < 7) {
          list1.push(el);
        } else if (index < 13) {
          list2.push(el);
        } else {
          list3.push(el);
        }
      },
    );
  }
  return {
    list1,
    list2,
    list3,
    list4,
  };
};

export const formatAllSeoList = ({ seoFooter, device }) => {
  const obj = {
    default: {},
    ptypes: {},
    nRooms: {},
  };
  Object.keys(seoFooter).map((firstKey) => {
    Object.keys(seoFooter[firstKey]).map((secondKey) => {
      const val = seoFooter[firstKey][secondKey];
      let ptypeForLinks = 'Case';
      if (firstKey === 'ptypes') {
        ptypeForLinks = SEOPageLinkPType({
          ptype: secondKey,
          numRooms: '',
        });
      } else if (firstKey === 'nRooms') {
        ptypeForLinks = SEOPageLinkPType({
          ptype: true,
          numRooms: secondKey,
        });
      }
      obj[firstKey][secondKey] = formatSeoList(val, ptypeForLinks, device);
    });
  });
  return obj;
};

export const chooseTab = ({ isAgencyHome, channel, onlyAuction }) => {
  let tab = 'buy';
  if (isAgencyHome) {
    tab = 'agency';
  } else if (onlyAuction) {
    tab = 'auction';
  } else if (channel === 'affitti' || channel === 'affitto') {
    tab = 'rent';
  }
  return tab;
};

export const chooseTabLink = ({
  isAgencyHome,
  category,
  ptype,
  numRooms,
  buildingCondition,
  onlyAuction,
}) => (!isAgencyHome && !onlyAuction) ? SEOPageTabLink(category, ptype, numRooms, buildingCondition) : '';


const divideInArray = (lista, isMobile) => {
  const n = isMobile ? 2 : 3; // numero di array in cui dividere la lista
  const result = [];

  const len = Math.ceil(lista.length / n); // lunghezza degli array risultanti, arrotondata per eccesso

  for (let i = 0; i < n; i++) {
    const start = i * len;
    const end = start + len;

    result.push(lista.slice(start, end));
  }

  return result;
};

export const createSeoFooterForBigCities = ({ list, ptypeForLinks = '', isMobile }) => {
  const arrays = (list && list.length > 0 && divideInArray(list, isMobile) || []);
  const toReturn = arrays.map((curr) => {
    return formatTowns(curr, ptypeForLinks, false);
  });
  return toReturn;
}