import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'shared-components/components/Grid';

import { useCtxGlobal } from '@client/app/Container';

import '../SeoFooter.css';
import { createSeoFooterForBigCities } from '../helpers/seoFooter';

const SeoFooterBigCities = ({
  data,
  hasSeFooterBigCities,
}) => {
  const { isMobile } = useCtxGlobal();
  const { affitto = [], vendita = [] } = data;

  const sectionVendita = createSeoFooterForBigCities({ list: vendita, isMobile, ptypeForLinks: 'tutte le case in vendita' });
  const sectionAffitto = createSeoFooterForBigCities({ list: affitto, isMobile, ptypeForLinks: 'tutte le case in affitto' });

  if (!hasSeFooterBigCities) {
    return null;
  }
  return (
    <Grid className="seoList sw" justify="space-between">
      <div className="regionsContainer seoBGContainer">
        <Grid className="regionsList seoBGCList">
          <Grid className="saleColumn" direction="column">
            <p className="tp-w--l tp-s--x2l mb--m">
              Case in vendita nelle principali città italiane
            </p>
            <dd className="noPoint">
              <Grid>
                {sectionVendita.map((l, x) => {
                  const n = x + 1; 
                  return (
                    <ul key={`${l}-${n}-buy`} className="listBGC">{l}</ul>
                  );
                })}
              </Grid>
            </dd>
          </Grid>
          <Grid className="rentColumn" direction="column">
            <p className="tp-w--l tp-s--x2l mb--m">
              Affitti nelle principali città italiane
            </p>
            <dd className="noPoint">
              <Grid>
                {sectionAffitto.map((l, x) => {
                  const n = x + 1; 
                  return (
                    <ul key={`${l}-${n}-rent`} className="listBGC">{l}</ul>
                  );
                })}
              </Grid>
            </dd>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default SeoFooterBigCities;

SeoFooterBigCities.propTypes = {
  data: PropTypes.instanceOf(Object),
  hasSeFooterBigCities: PropTypes.bool,
};

SeoFooterBigCities.defaultProps = {
  data: {},
  hasSeFooterBigCities: false,
};
