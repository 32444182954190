import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';

import HpArrow from 'shared-vectors/icons/HpArrow';
import Arrow from 'shared-vectors/icons/Arrow';
import { Icon } from 'shared-vectors';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import { queryBuilder } from '@modals/factories';

import { useCtxGlobal } from '@client/app/Container';

const propTypes = {
  value: PropTypes.string,
  matchValue: PropTypes.string,
  values: PropTypes.instanceOf(Array),
  scope: PropTypes.string,
  label: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleSelect: PropTypes.func,
  storeSelectValue: PropTypes.func,
  visible: PropTypes.bool,
  availableResults: PropTypes.string,
  activeFilters: PropTypes.instanceOf(Object),
  device: PropTypes.string,
};

const defaultProps = {
  value: '',
  matchValue: '',
  values: [],
  scope: '',
  label: '',
  isOpen: false,
  toggleSelect: () => { },
  storeSelectValue: () => { },
  visible: true,
  availableResults: '',
  activeFilters: {},
  device: '',
};

const createLabel = (value, values) => {
  const fixedRentValue = value === 'affitti'
    ? 'affitto'
    : value;
  let categoryConf = values.filter(item => item.slug === fixedRentValue)[0];
  if (typeof categoryConf === 'undefined') {
    const filterOptionWithChildren = values.filter(item => 'children' in item)[0];
    categoryConf = filterOptionWithChildren ? filterOptionWithChildren.children.filter(item => item.slug === fixedRentValue)[0] : {};
  }
  return categoryConf.label === 'Immobili e attività commerciali' ? 'Imm. e att. comm.' : categoryConf.label;
};

const expandWithChildren = (evt) => {
  if (evt.target.parentNode.classList.contains('expanded')) {
    evt.target.parentNode.classList.remove('expanded');
    evt.target.parentNode.parentNode.parentNode.classList.remove('submenu');
  } else {
    evt.target.parentNode.classList.add('expanded');
    evt.target.parentNode.parentNode.parentNode.classList.add('submenu');
  }
};

const createOption = (scope, option, currentFilterValue, matchValue, storeSelectValue) => {

  let cssClassName = 'children' in option ? 'withChildren' : null;
  if (option.slug === currentFilterValue) {
    cssClassName = cssClassName !== null ? `${cssClassName} selected` : 'selected';
  }
  if (scope === 'channel') {
    if (
      (
        matchValue === 'stanze'        
      ) &&
      option.slug === 'vendita'
    ) {
      cssClassName = cssClassName !== null ? `${cssClassName} disabled` : 'disabled';
    }
    if (
      matchValue !== 'case'
      && option.slug === 'affitto-breve'
    ) {
      cssClassName = cssClassName !== null ? `${cssClassName} disabled` : 'disabled';
    }
  }
  if ('children' in option) {
    return null;
  }
  return (
    <li
      key={`${scope}_option_${Math.random()}`}
      className={cssClassName}
    >
      <button
        type="button"
        className="hpFormSelectBtn"
        aria-label={scope === 'category' ? `Cerca immobili nella categoria ${option.label}` : `Cerca immobili in ${option.label}`}
        onClick={(e) => {
          if ('children' in option) {
            expandWithChildren(e);
          } else {
            storeSelectValue({ [scope]: option.slug });
          }
        }}
      >
        {option.label}
      </button>
    </li>
  );
};

const agencyTracking = () => trackGTMEvents({
  category: 'Interaction',
  action: 'PushButton',
  label: 'GoToAgencySearchHP',
});

const createAgencyButton = () => (
  <li>
    <a
      aria-label="agency"
      className="agencyBtn"
      href="/agenzie/"
      onClick={agencyTracking}
    >
      Agenzia
      <Icon name={Arrow} color="secondary" heigth="10px" width="13px" />
    </a>

  </li>
);

const Select = ({
  value,
  matchValue,
  values,
  scope,
  label,
  isOpen,
  toggleSelect,
  storeSelectValue,
  visible,
  availableResults,
  activeFilters,
  device,
}) => {
  const el = useRef(null);
  const options = useRef(null);
  const [coords, setCoords] = useState(null);
  const [over, setOver] = useState(0);
  const [topLeft, setTopLeft] = useState(null);
    
  useEffect(() => {
    if (!isOpen) {
      over && setTimeout(() => {
        setOver(0);
      }, 401);
    } else {
      setOver(1);
    }
  }, [isOpen]);

  const {
    isMobile,
    isSmallMobile,
    openModal,
    pageType,
    filtersProps,
  } = useCtxGlobal();

  const openQbPtypes = () => {
    toggleSelect(scope);
    openModal && openModal(queryBuilder({
      isMobile,
      isSmallMobile,
      availableResults,
      pageType,
      filtersProps,
      panelChooseBy: {
        isMobile,
        actionClicked: 'propertyTypeGroupSelect',
        pageFrom: pageType,
      },
    }));
  };

  const openQbPGroups = () => {
    openModal && openModal(queryBuilder({
      isMobile,
      isSmallMobile,
      availableResults,
      pageType,
      filtersProps,
      panelChooseBy: {
        isMobile,
        actionClicked: 'propertyTypeGroupButton',
        pageFrom: pageType,
      },
    }));
  };  

  const action = () => {
    if (scope === 'category' && activeFilters.activeFiltersCount > 0) {
      openQbPGroups();
    } else {      
      const { top, height } = el.current.getBoundingClientRect();
      const { height: selectH } = options.current.getBoundingClientRect();

      if (device !== 'smartphone') {
        let x = (height / 2) - (Math.ceil(selectH / 1.333) / 2);

        if (top < Math.abs(x)) {
          x = (top - 24) * -1;
        }

        setCoords(`${x}px`);
      } else if (window.scrollY) {
        if ((top + height + selectH) < (document.documentElement.clientHeight + window.scrollY)) {
          setCoords(0);
          setTopLeft({ position: 'absolute', top: '100%', left: 0 });
        }
      } else {
        setCoords(null);
        setTopLeft(null);
      }
      toggleSelect(scope);
    }
  };

  let cls = `hpFormOption ${scope}`;
  if (isOpen) {
    cls += ' active';
  }
  if (over) {
    cls += ' over';
  }
  if (topLeft) {
    cls += ' is-rel';
  }

  let style = null;
  if (isOpen) {
    style = { transform: `scaleY(1) translateY(${coords})` };
    if (topLeft) {
      style = { ...style, ...topLeft };
    }
  }
  if (!isOpen && over && topLeft) {
    style = { position: 'absolute', top: '100%', left: 0 };
  } 

  return (
    <div
      id={`${scope}Select`}
      className={cls}
      style={!visible ? { display: 'none' } : {}}
      ref={el}
    >
      <p className="hpFormLabel">
        {label}
      </p>
      <div className="hpFormSelect">
        <Grid onClick={action} className="is-clickable">
          <GridItem className="tp--ell">
            {createLabel(value, values)}
          </GridItem>
          <GridItem behavior="fixed" className="pl--m">
            <Icon name={HpArrow} width="12px" height="7px" className={`${isOpen ? 'rotate--180' : ''} is-anim mb--xs`} fill />
          </GridItem>
        </Grid>
      </div>
      <div
        ref={options}
        className="hpFormSelectOptions"
        style={style}
      >
        <ul>
          {values.map(option => createOption(scope, option, value, matchValue, storeSelectValue))}
          {
            scope === 'category'
              && (
                <>
                  <li className="hpFormSelectMdlCtn mt--m">
                    <button
                      type="button"
                      className="hpFormSelectMdl tp-s--l tp-w--m c-txt--secondary tp--u"
                      onClick={openQbPtypes}
                    >
                      Vedi lista completa
                    </button>
                  </li>
                  {createAgencyButton(scope)}
                </>
              )
          }
        </ul>
      </div>
    </div>
  );
};
Select.propTypes = propTypes;
Select.defaultProps = defaultProps;
export default Select;
