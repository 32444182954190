import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  category: PropTypes.string,
};

const defaultProps = {
  category: '',
};

const createBgContainer = (name) => {
  const htmlNode = document.createElement('li');
  htmlNode.id = `splash_bg_${name}`;

  const d = new Date();
  const month = d.getMonth(); 
  const day = d.getDate();

  let clsName = name.replace(/\+/g, '-');
  if ((month === 11 && day > 7) || (month === 0 && day < 7)) {
    clsName = name === 'case' ? 'xmas' : clsName;
  }

  htmlNode.className = clsName;
  return htmlNode;
};

class AnimatedSplash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this[this.props.category].classList.add('active');
    this.createSlide(this.props.category);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.category !== this.props.category) {
      const fadeIn = nextProps.category;
      const fadeOut = this.props.category;
      if (document.querySelector(`#splash_bg_${fadeIn}`) === null) {
        this.createSlide(fadeIn);        
      }
      this.animateSlides(fadeIn, fadeOut);
    }
  }

  createSlide = (name) => {
    const bgContainer = createBgContainer(name);
    this[name] = bgContainer;
    bgContainer.classList.add('active');
    this.splashbg.appendChild(bgContainer);
  }

  animateSlides = (fadeIn, fadeOut) => {
    this[fadeOut].classList.remove('active');
    this[fadeOut].classList.add('fadeout');
    this[fadeIn].classList.add('fadein');
    setTimeout(
      () => {
        this[fadeIn].classList.remove('fadein');
        this[fadeIn].classList.add('active');
        this[fadeOut].classList.remove('fadeout');
      },
      1000,
    );
  }

  render() {
    return (
      <ul
        ref={(node) => { this.splashbg = node; }}
        id="splashbg"
      />
    );
  }
}
AnimatedSplash.propTypes = propTypes;
AnimatedSplash.defaultProps = defaultProps;
export default AnimatedSplash;
