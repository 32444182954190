import React from 'react';
import PropTypes from 'prop-types';

import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';

import Spinner from '@components/common/Spinner';

const propTypes = {
  device: PropTypes.string,
  category: PropTypes.string,
  categoryValues: PropTypes.instanceOf(Array),
  availableResults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  submitSearch: PropTypes.func,
  isFetching: PropTypes.bool,
  isAgencyHome: PropTypes.bool,
  agencyType: PropTypes.string,
  locations: PropTypes.instanceOf(Array),
  filters: PropTypes.instanceOf(Object),
  follow: PropTypes.bool,
};

const defaultProps = {
  device: '',
  category: '',
  categoryValues: [],
  availableResults: '',
  submitSearch: () => { },
  isFetching: false,
  isAgencyHome: false,
  agencyType: '',
  locations: [],
  filters: {},
  follow: false,
};

const createLabelAgency = ({ availableResults, agencyType, follow }) => {
  if (follow) {
    return <><strong>Prosegui</strong></>;
  }
  if (availableResults === '') {
    return agencyType === 'agencyPdp' ? 'Vedi agenzia' : 'Vedi risultati';
  } 
  if (parseInt(availableResults, 10) === 0) {
    return 'Nessuna agenzia disponibile';
  }
  return (
    <span>
      Vedi
      {' '}
      <strong>{availableResults}</strong>
      {' '}
      risultati
    </span>
  );
};

const createLabel = (device, availableResults, value, values, { isAgencyHome, agencyType, follow }) => {

  if (isAgencyHome) {
    return createLabelAgency({
      availableResults,
      value,
      values,
      agencyType,
      follow,
    });
  }
  if (availableResults === '') {
    return 'Vedi risultati';
  }
  let categoryConf = values.filter(item => item.slug === value)[0];
  if (!categoryConf) {
    const filterOptionWithChildren = values.filter(item => 'children' in item)[0];
    [categoryConf] = filterOptionWithChildren.children.filter(item => item.slug === value);
    // categoryConf.ctaNoResultLabel
  }

  if (parseInt(availableResults, 10) === 0) {
    return (
      <span>Vedi Opzioni</span>
    );
  }
  return <>Vedi <strong>{availableResults}</strong> risultati</>;
  // return (
  //   <span>
  //     Vedi
  //     {' '}
  //     <strong>{availableResults}</strong>
  //     {' '}
  //     {device === 'smartphone' && categoryConf.ctaLabel === 'Immobili e attività commerciali' ? 'Imm. e att. commerciali' : categoryConf.ctaLabel}
  //   </span>
  // );
};

const isButtonAvailable = ({
  locations,
  filters = {},
  follow,
}) => {
  if (!locations.length && !filters.nearby && !follow) {
    return false;
  }
  return true;
};

const SearchButton = ({
  device,
  category,
  categoryValues,
  availableResults,
  submitSearch,
  isFetching,
  isAgencyHome,
  agencyType,
  locations,
  filters,
  follow,
}) => {
  const cssClassName = isButtonAvailable({
    locations,
    filters,
    follow,
  }) ? 'searchButton available' : 'searchButton';

  const onClickAction = (e) => {
    let trackObj;
    if (follow) {
      trackObj = {
        category: 'AgencyRecommender',
        action: 'GoToAgencyRecommender',
        position: 'AgenciesHP',
      };
    } else {
      trackObj = {
        category: 'Interaction',
        action: 'PushButton',
        label: 'SeeResultsFromHP',
      };
    }
    trackGTMEvents(trackObj);
    submitSearch(e);
  };

  const zeroResults = !isFetching && !!availableResults && parseInt(availableResults, 10) === 0;
  
  return (
    <div id="searchButton" className={cssClassName}>
      {
        zeroResults
          && <p className={`pb--xs tp-s--x${device === 'smartphone' ? '2' : ''}s tp-w--m c-txt--w`}>Nessun risultato al momento</p>
      }
      <button aria-label="Avvia la ricerca" type="button" onClick={onClickAction}>
        {
          isFetching && !follow
            ? <Spinner valignCenter="true" size="l" color="w" />
            : createLabel(device, availableResults, category, categoryValues, { isAgencyHome, agencyType, follow })
        }
      </button>
    </div>
  );
};
SearchButton.propTypes = propTypes;
SearchButton.defaultProps = defaultProps;
export default SearchButton;
